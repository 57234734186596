:root {
    --dark: #494947;
    --accent: #ff9a44;
}

body {
    font-family: "Nunito", sans-serif;
    color: white;
    font-size: 1.2rem;
}

.gradient-bg {
    /* background-image: linear-gradient(-20deg, #b721ff 0%, #21d4fd 100%); */
    background-image: linear-gradient(-20deg, #AC32E4 0%, #7918F2 48%, #4801FF 100%);
}

.bottom-15 {
    bottom: 15%;
}

.opacity-25 {
    opacity: .25;
}

.opacity-50 {
    opacity: .5;
}

.display-2, .display-4 {
    font-weight: 800;
    transition: opacity .2s;
}

.custom-card {
    border-radius: 15px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 2rem;
    padding-bottom: 2.5rem;
    background-image: linear-gradient(-225deg, #FFFEFF 0%, #D7FFFE 100%);
    color: var(--dark);
}

.pointer-cursor {
    cursor: pointer;
}

#file-upload {
    /* width: 50%; */
    /* position: absolute; */
    /* right: 0; */
    /* top: 15%; */
    background-image: linear-gradient(-225deg, #FFFEFF 0%, #D7FFFE 100%);
}

#progressBar {
    bottom: 5%;
    left: 5%;
    right: 5%;
}

.input-form {
    background: transparent !important;
    border: none;
    border-bottom: 1.5px solid var(--dark);
    border-radius: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: var(--dark);
    font-size: 1.2rem;
    font-weight: 400;
}

.text-accent-colored {
    color: var(--accent) !important;
}

#inputEmail:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

#file-upload #inputEmail::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--dark);
    opacity: 1; /* Firefox */
}

#file-upload #inputEmail:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--dark);
}

#file-upload #inputEmail::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--dark);
}

.custom-progress {
    background-color: rgba(255, 255, 255, .2);
}

.progress-bar {
    background-image: linear-gradient(-20deg, #fc6076 0%, #ff9a44 100%);
}

#fileName {
    font-weight: 300;
    color: var(--dark);
}

.btn-outline-dark {
    color: var(--dark);
    border-color: var(--dark);
}

.btn-outline-dark:hover {
    background-color: var(--dark);
    border-color: var(--dark);
}

.btn-primary {
    color: white;
    font-weight: 700;
    background-image: linear-gradient(-20deg, #fc6076 0%, #ff9a44 100%);
    border: none;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    padding: 1rem 3rem;
}

.btn-primary:hover {
    background-image: linear-gradient(-20deg, #ff334e 0%, #ff8923 100%);
}

.btn-primary img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(90deg) brightness(106%) contrast(101%);
}

/* #landing {
    height: calc(100vh - 50px);
} */

#logo {
    text-align: center;
    filter: invert(100%) sepia(5%) saturate(0%) hue-rotate(295deg) brightness(105%) contrast(106%);
}

.shadow {
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);
}

.circle-btn {
    height: 30px;
    line-height: 30px;  
    width: 30px;  
    font-size: 1rem;
    font-weight: 700;
    border-radius: 50%;
    background-color: var(--accent);
    color: white;
    text-align: center;
    cursor: pointer;
}

.circle-btn:hover {
    background-color: #ff8923;
}

.instruction-number {
    height: 40px;
    line-height: 40px;  
    width: 40px;  
    font-size: 1.5rem;
    font-weight: 700;
    border-radius: 50%;
    background-color: var(--accent);
    color: white;
    text-align: center;
    left: -10px;
    top: -10px;
}

#help-btn {
    margin-bottom: -1rem;
}

.section {
    min-height: calc(100vh - 100px);
}

.tabs {
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column-gap: 25px;
    grid-row-gap: 25px;
}

.border-left {
    border-left: 3px solid var(--accent);
}

footer {
    margin-top: 10rem;
    background-image: linear-gradient(20deg, #fc6076 0%, #ff9a44 100%);
    min-height: 150px;
}

.move-left-hover {
    transition: margin-left .4s, margin-right .4s;
}

.move-left-hover:hover {
    margin-left: -20px;
    margin-right: 20px;
}

@media (min-width: 576px) {
    #progressBar {
        left: 0;
        right: 0;
    }

    #logo {
        text-align: left;
    }
}

@media (min-height: 700px) {
    #progressBar {
        bottom: 15%;
    }
}

@media (min-height: 800px) {
    #progressBar {
        bottom: 20%;
    }
}

@media (min-width: 992px) {
    #tagline {
        margin-bottom: 9rem;
        align-self: flex-end;
    }

    /* #landing-row {
        height: 100%;
    } */

}